import styled from "styled-components";
import { colors } from "../../constants/colors";
import { StyledText } from "../../constants/text";

export const Footer = () => {
  return (
    <StyledFooterContainer>
      <StyledText.Subtitle_4 color={colors.shade_2}>
        2024 @ salt. Inc
      </StyledText.Subtitle_4>
    </StyledFooterContainer>
  );
};

const StyledFooterContainer = styled.div`
width: 90%;
margin-top: 100px;

border-color: ${colors.neutral_3};
border-top-width: 1px;
border-top-style: solid;
padding-top: 13px;
padding-bottom: 90px;
`;
