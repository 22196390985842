import styled from "styled-components";
import { Icon } from "../Icon";
import { IconRowItem } from "../molecules/IconRowItem";
import { colors } from "../../constants/colors";

export const CostRowPanel = () => {
  return (
    <StyledCostRowPanelContainer>
      <IconRowItem
        icon={<Icon type="youtube" />}
        text="youtube partner program"
        numberText="$815"
      />
      <IconRowItem
        icon={<Icon type="tiktok" />}
        text="tiktok creator fund"
        numberText="$558"
      />
      <IconRowItem
        icon={<Icon type="cart" />}
        text="affiliate sales"
        numberText="$1255"
        borderBottom={false}
      />
      <IconRowItem isBig borderBottom={false} numberText="$4,566" />
      <IconRowItem
        icon={<Icon type="chart" />}
        text="cookbook sales"
        numberText="$2334"
      />
      <IconRowItem
        icon={<Icon type="google" />}
        text="google ads"
        numberText="$989"
      />
      <IconRowItem
        icon={<Icon type="x" />}
        text="without salt"
        numberText="$6426/mo"
        numberTextColor={colors.error_2}
        borderBottom={false}
        strikeNumber
      />
      <IconRowItem
        icon={<Icon type="salt" />}
        textFontWeight={700}
        numberFontWeight={700}
        text="join the salt fam"
        numberText="$11,676/mo"
        textColor={colors.shade_2}
        numberTextColor="#1A5E3D"
      />
    </StyledCostRowPanelContainer>
  );
};

const StyledCostRowPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-color: ${colors.neutral_3};
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  justify-content: center;
  align-items: center;
  z-index: 0;
  width: 319px;
  background-color: ${colors.neutral_1};
  box-shadow: 0px 20px 44px 0px rgba(0, 0, 0, 0.05),
    0px 79px 79px 0px rgba(0, 0, 0, 0.04),
    0px 179px 107px 0px rgba(0, 0, 0, 0.03),
    0px 318px 127px 0px rgba(0, 0, 0, 0.01),
    0px 496px 139px 0px rgba(0, 0, 0, 0);

  @media screen and (min-width: 744px) {
    width: 520px;
  }
  @media screen and (min-width: 1024px) {
    width: 722px;
  }
  transition: width 0.5s ease;
`;
