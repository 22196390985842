import styled from "styled-components";
import A from "../../assets/images/carosel_1/A.png";
import B from "../../assets/images/carosel_1/B.png";
import C from "../../assets/images/carosel_1/C.png";
import { CorselSubheading } from "../atoms/CaroselSubheading";

export const ImagePanel:React.FC = () => {
return (
    <StyledMainContainer>
        <StyledContentContainer>
            <StyledPanelImageContainer>
                <StyledPanelImage src={A} alt={"A"}/>
            </StyledPanelImageContainer>
            <CorselSubheading
              boldText="Save. "
              regularText="Organize a personal recipe box with custom folders. "
            />
        </StyledContentContainer>
        <StyledContentContainer>
            <StyledPanelImageContainer>
                <StyledPanelImage src={B} alt={"B"}/>
            </StyledPanelImageContainer>
            <CorselSubheading
              boldText="Simplify. "
              regularText="Display your recipes in a clean format, no fluff."
            />
        </StyledContentContainer>
        <StyledContentContainer>
            <StyledPanelImageContainer>
                <StyledPanelImage src={C} alt={"C"}/>
            </StyledPanelImageContainer>
            <CorselSubheading
              boldText="Season. "
              regularText="Link your profile in social bios. Make more $ than google-ads on your website."
            />
        </StyledContentContainer>
    </StyledMainContainer>
    
    )
}


const StyledPanelImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;

`;

const StyledPanelImageContainer = styled.div`
  display: flex;
  width: 169px;
  height: auto;

  @media screen and (min-width: 1024px) {
    width: 249px;
  }
  @media screen and (min-width: 1440px) {
    width: 275px;
  }
  transition: width 0.5s ease;
`;

const StyledMainContainer = styled.div`
display: flex;
flex-direction: row;
gap: 38.2px;
  @media screen and (min-width: 834px) {
    gap: 84px;
  }
  @media screen and (min-width: 1024px) {
    gap: 57.68px;
  }
  @media screen and (min-width: 1440px) {
    gap: 145.23px;
  }

  transition: gap 0.5s ease;
`