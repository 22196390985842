import React, { useState, useRef } from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import A from "../../assets/images/carosel_1/A.png";
import B from "../../assets/images/carosel_1/B.png";
import C from "../../assets/images/carosel_1/C.png";
import styled from "styled-components";
import { CorselSubheading } from "../atoms/CaroselSubheading";
import { colors } from "../../constants/colors";

const Carousel: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const sliderRef = useRef<Slider>(null);

  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
    afterChange: (current) => {
      setCurrentSlide(current);
    },
    beforeChange: (currentSlide, nextSlide) => {
      setCurrentSlide(nextSlide);
    },
  };

  return (
    <div>
      <Slider {...settings} ref={sliderRef}>
        <div>
          <StyledContentContainer>
            <StyledCarouselImageContainer>
              <StyledCarouselImage src={A} alt={"A"} />
            </StyledCarouselImageContainer>
            <CorselSubheading
              boldText="Save. "
              regularText="Organize a personal recipe box with custom folders. "
            />
          </StyledContentContainer>
        </div>
        <div>
          <StyledContentContainer>
            <StyledCarouselImageContainer>
              <StyledCarouselImage src={B} alt={"B"} />
            </StyledCarouselImageContainer>
            <CorselSubheading
              boldText="Simplify. "
              regularText="Display your recipes in a clean format, no fluff."
            />
          </StyledContentContainer>
        </div>
        <div>
          <StyledContentContainer>
            <StyledCarouselImageContainer>
              <StyledCarouselImage src={C} alt={"C"} />
            </StyledCarouselImageContainer>
            <CorselSubheading
              boldText="Season. "
              regularText="Link your profile in social bios. Make more $ than google-ads on your website."
            />
          </StyledContentContainer>
        </div>
      </Slider>
      <StyledDotContainerMaster>
      <StyledDotContainer>
        <StyledDot onClick={() => sliderRef.current?.slickGoTo(0)} isActive={currentSlide === 0} />
        <StyledDot onClick={() => sliderRef.current?.slickGoTo(1)} isActive={currentSlide === 1} />
        <StyledDot onClick={() => sliderRef.current?.slickGoTo(2)} isActive={currentSlide === 2} />
      </StyledDotContainer>
      </StyledDotContainerMaster>
    </div>
  );
};

const StyledCarouselImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledCarouselImageContainer = styled.div`
  display: flex;
  width: 250px;
`;

const StyledDotContainer = styled.div`
  display: inline-flex;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  height: 56px;
  border-radius: 30px;
  border-width: 1px;
  border-color: ${colors.neutral_3};
  border-style: solid;
  background-color: ${colors.neutral_1};
  cursor: pointer;
`;

const StyledDotContainerMaster = styled.div`
display: flex;
justify-content: center;
position: relative;
opacity: 1;
@media screen and (min-width: 744px) {
    position: absolute;
opacity: 0;
  }
`

const StyledDot = styled.div<{ isActive: boolean }>`
  width: ${({ isActive }) => (isActive ? 48 : 8)}px;
  height: 8px;
  border-radius: 4px;
  background-color: ${colors.neutral_7};
  transition: width 0.5s ease;
`;

export default Carousel;
