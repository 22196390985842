import { Home } from './screens/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from "react"
import WebFont from 'webfontloader';
import './index.css'

function App() {

  return (
    <Router>
        <Routes>
            <Route path="/" element={<Home/>} />
        </Routes>
    </Router>
  );
}

export default App;
