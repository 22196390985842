import { ReactElement } from "react";
import { Icon } from "../Icon";
import styled from "styled-components";
import { StyledText } from "../../constants/text";
import { colors } from "../../constants/colors";

interface IconRowItemProps {
  icon?: ReactElement<typeof Icon>;
  text?: string;
  numberText: string;
  strikeText?: boolean;
  textColor?: string;
  numberTextColor?: string;
  borderBottom?: boolean;
  numberOffset?: number;
  isBig?: boolean;
  textFontWeight?: number;
  numberFontWeight?: number;
  strikeNumber?: boolean;
}

export const IconRowItem: React.FC<IconRowItemProps> = ({
  icon,
  strikeText,
  text,
  numberText,
  textColor = colors.neutral_7,
  numberTextColor = colors.neutral_7,
  borderBottom = true,
  numberOffset = 0,
  isBig = false,
  textFontWeight = 500,
  numberFontWeight = 500,
  strikeNumber = false,
}) => {
  return (
    <StyledRowItemContainer borderBottom={borderBottom} isBig={isBig}>
      <StyledIconContainer isBig={isBig}>
        {isBig ? <StyledLogo color={colors.primary_1}>salt</StyledLogo> : icon}
      </StyledIconContainer>
      <StyledMiddleText middleFontWeight={textFontWeight} color={textColor}>
        {text}
      </StyledMiddleText>
      {isBig ? (
        <StyledRightTextBold color={"#222"}>{numberText}</StyledRightTextBold>
      ) : (
        <StyledRightText
          strikeNumber={strikeNumber}
          rightFontWeight={numberFontWeight}
          color={numberTextColor}
        >
          {numberText}
        </StyledRightText>
      )}
    </StyledRowItemContainer>
  );
};

const StyledRowItemContainer = styled.div<{
  borderBottom: boolean;
  isBig: boolean;
}>`
  display: flex;
  position: relative;
  align-items: center;
  z-index: 2;
  background-color: ${({ isBig }) => (isBig ? "#F4F4F4" : "transparent")};

  width: ${({ isBig }) => (isBig ? 327 : 283)}px;
  height: ${({ isBig }) => (isBig ? 67 : 47)}px;
  border-width: ${({ isBig }) => (isBig ? 1 : 0)}px;
  border-style: solid;
  border-bottom-width: ${({ borderBottom, isBig }) =>
    borderBottom || isBig ? 1 : 0}px;
  border-bottom-style: solid;
  border-color: ${colors.neutral_3};
  border-radius: ${({ isBig }) => (isBig ? 10 : 0)}px;
  box-shadow: ${({ isBig }) =>
    isBig
      ? `0px 21px 46px 0px rgba(0, 0, 0, 0.05), 0px 83px 83px 0px rgba(0, 0, 0, 0.04), 0px 187px 112px 0px rgba(0, 0, 0, 0.03), 0px 333px 133px 0px rgba(0, 0, 0, 0.01), 0px 520px 146px 0px rgba(0, 0, 0, 0.00)`
      : ""};

  @media screen and (min-width: 744px) {
    width: ${({ isBig }) => (isBig ? 560 : 496)}px;
    height: ${({ isBig }) => (isBig ? 127 : 74)}px;
  }

  @media screen and (min-width: 1024px) {
    width: ${({ isBig }) => (isBig ? 842 : 553)}px;
  }

  transition: all 0.5s ease;
`;

const StyledIconContainer = styled.div<{
  isBig: boolean;
}>`
  position: absolute;
  left: ${({ isBig }) => (isBig ? 24 : 6)}px;

  @media screen and (min-width: 744px) {
    left: ${({ isBig }) => (isBig ? 46 : 29)}px;
  }
  transition: left 0.5s ease;
`;

const StyledMiddleText = styled(StyledText.Subtitle_4)<{
  middleFontWeight: number;
}>`
  position: absolute;
  left: 45px;
  font-weight: ${({ middleFontWeight }) => middleFontWeight};
  @media screen and (min-width: 744px) {
    left: 95px;
  }
  transition: left 0.5s ease;
`;

const StyledRightText = styled(StyledText.Subtitle_4)<{
  rightFontWeight: number;
  strikeNumber: boolean;
}>`
  position: absolute;
  right: 0px;
  font-weight: ${({ rightFontWeight }) => rightFontWeight};
  text-decoration: ${({ strikeNumber }) =>
    strikeNumber ? "line-through" : "none"};
  @media screen and (min-width: 744px) {
    right: 85px;
  }
  transition: right 0.5s ease;
`;

const StyledRightTextBold = styled(StyledText.Title_4)`
  position: absolute;
  right: 25px;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  @media screen and (min-width: 744px) {
    right: 93px;
  }
  transition: right 0.5s ease;
`;

const StyledLogo = styled(StyledText.Logo_3)`
  font-size: 24px;

  @media screen and (min-width: 744px) {
    font-size: 64px;
  }

  transition: font-size 0.5s ease;
`;
