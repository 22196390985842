import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { StyledText } from "../constants/text";
import { colors } from "../constants/colors";
import { Icon } from "./Icon";

export const Nav = () => {
  return (
    <StyledNavContainer>
      <StyledLeftContainer>
        <StyledLogo color={colors.primary_1}>salt</StyledLogo>
        <StyledCreatorsContainer>
          <StyledText.Title_5 color={colors.shade_2}>
            Creators
          </StyledText.Title_5>
        </StyledCreatorsContainer>
      </StyledLeftContainer>
      <StyledRightContainer>
        <StyledRightTextContainer>
          <StyledSignupContainer>
            <StyledText.Title_5 color={colors.shade_2}>
              Sign up
            </StyledText.Title_5>
          </StyledSignupContainer>

          <StyledLoginContainer>
            <StyledText.Title_5 color={colors.shade_2}>
              Login
            </StyledText.Title_5>
          </StyledLoginContainer>
        </StyledRightTextContainer>
      </StyledRightContainer>
      <StyledMenu>
        <Icon type="bars" />
      </StyledMenu>
    </StyledNavContainer>
  );
};

const StyledLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: 1920px) {
    gap: 120px;
  }
  @media screen and (max-width: 1024px) {
    gap: 65px;
  }
  @media screen and (max-width: 834px) {
    gap: 61px;
  }
  @media screen and (max-width: 430px) {
  }
  transition: gap 0.5s ease;
`;

const StyledRightContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledRightTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 38px;
  position: relative;
  opacity: 1;
  @media screen and (max-width: 743px) {
    opacity: 0;
  }
`;

const StyledCreatorsContainer = styled.div`
  display: flex;
  opacity: 1;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 743px) {
    opacity: 0;
  }
`;

const StyledLoginContainer = styled.div`
  cursor: pointer;
  display: flex;
  width: 106px;
  height: 50px;
  border-radius: 36.5px;
  justify-content: center;
  align-items: center;
  border-color: ${colors.neutral_3};
  border-width: 1px;
  border-style: solid;
  background-color: ${colors.shade_1};
  box-shadow: 0px 11px 24px 0px rgba(0, 0, 0, 0.05),
    0px 44px 44px 0px rgba(0, 0, 0, 0.04),
    0px 100px 60px 0px rgba(0, 0, 0, 0.03),
    0px 178px 71px 0px rgba(0, 0, 0, 0.01), 0px 278px 78px 0px rgba(0, 0, 0, 0);
`;

const StyledSignupContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const StyledNavContainer = styled.nav`
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 21px;
  padding-right: 18px;
  padding-top: 50px;
  @media screen and (min-width: 744px) {
    padding-left: 80px;
    padding-right: 80px;
  }
  @media screen and (min-width: 1440px) {
    padding-left: 127px;
    padding-right: 90px;
  }
  transition: padding-left 0.5s ease;
`;

const StyledMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border-color: ${colors.neutral_3};
  background-color: ${colors.shade_1};
  border-style: solid;
  border-width: 1px;
  position: absolute;
  right: 18px;
  opacity: 1;

  @media screen and (min-width: 744px) {
    opacity: 0;
  }
`;

const StyledLogo = styled(StyledText.Logo_1)`
  font-size: 32px;
  @media screen and (min-width: 744px) {
    font-size: 48px;
  }
  transition: font-size 0.5s ease;
`;
