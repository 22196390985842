import styled from "styled-components";
import { StyledText } from "../../constants/text";
import { colors } from "../../constants/colors";

interface CaroselSubheadingProps {
  boldText: string;
  regularText: string;
}

export const CorselSubheading: React.FC<CaroselSubheadingProps> = ({

  boldText,
  regularText,
}) => {
  return (
    <StyledTextContainer>
      <StyledBoldText>{boldText}</StyledBoldText>
      <StyledRegularText>{regularText}</StyledRegularText>
    </StyledTextContainer>
  );
};

const StyledTextContainer = styled.p`
  width: 242px;
  padding-left: 10px;
  @media screen and (min-width: 744px) {
    width: 169px;
  }
  @media screen and (min-width: 1024px) {
    width: 242px;
  }
  @media screen and (min-width: 1440px) {
    width: 277px;
  }
  transition: width 0.5s ease;

`;

const StyledBoldText = styled.span`
  font-family: Satoshi;
  font-size: 20px;
  font-weight: 700;
  color: ${colors.shade_2};


  @media screen and (min-width: 744px) {
    font-size: 16px;
  }
  @media screen and (min-width: 1024px) {
    font-size: 20px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 24px;
  }
  transition: font-size 0.5s ease;

`;

const StyledRegularText = styled.span`
  font-family: Satoshi;
  font-size: 20px;
  font-weight: 700;
  color: ${colors.neutral_7};

  @media screen and (min-width: 744px) {
    font-size: 16px;
  }
  @media screen and (min-width: 1024px) {
    font-size: 20px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 24px;
  }
  transition: font-size 0.5s ease;
`;
