export const colors = {
    //Shades
    shade_1: '#FFFFFF',
    shade_2: '#222222',
    shade_3: '#22222205',
    shade_4: '#22222230',
    shade_5: '#F6D7DF',
    //Neutrals
    neutral_1: '#F7F7F7',
    neutral_2: '#EBEBEB',
    neutral_3: '#DDDDDD',
    neutral_4: '#D3D3D3',
    neutral_5: '#C2C2C2',
    neutral_6: '#BBBBBB',
    neutral_7: '#717171',
    neutral_8: '#5E5E5E',
    //Primary
    primary_1: '#FF5A57',
    //Error
    error_1: '#FEF8F6',
    error_2: '#C13515',
  };
  