import styled from "styled-components";
import { CreatorPill } from "../molecules/CreatorPill";
import React from "react";

//Screens
import Ramsay from "../../assets/images/creator_profiles/gordonramsaymock.png";
import Cbum from "../../assets/images/creator_profiles/cbummock.png";
import Preppy from "../../assets/images/creator_profiles/preppykitchenmock.png";
import Ella from "../../assets/images/creator_profiles/delciouslyellamock.png";
import Dooby from "../../assets/images/creator_profiles/doobydobapmock.png";
import Feelgood from "../../assets/images/creator_profiles/feelgoodfoodiemock.png";

//Headshots
import RamsayHead from "../../assets/images/creator_headshots/gordonprofile.png";
import CbumHead from "../../assets/images/creator_headshots/cbumprofile.png";
import PreppyHead from "../../assets/images/creator_headshots/preppykitchenprofile.png";
import EllaHead from "../../assets/images/creator_headshots/delicouslyellaprofile.png";
import DoobyHead from "../../assets/images/creator_headshots/doobyprofile.png";
import FeelgoodHead from "../../assets/images/creator_headshots/feelgoodfoodieprofile.png";

export const CreatorPanel = () => {
  const [currentCreator, setCurrentCreator] = React.useState(0);
  const images = [Ramsay, Feelgood, Preppy, Dooby, Ella, Cbum]
  return (
    <StyledMasterContainer>
    <StyledPanelContainer>
      <StyledCreatorOptionsContainer>
        <CreatorPill
          onClick={() => setCurrentCreator(0)}
          title="Gordon Ramsay"
          subtitle="Idiot sandwhich"
          image={RamsayHead}
          isActive={currentCreator === 0}
          alwaysShadow={false}
        />

        <CreatorPill
          onClick={() => setCurrentCreator(1)}
          title="feelgoodfoodie"
          subtitle="always feel good"
          image={FeelgoodHead}
          isActive={currentCreator === 1}
          alwaysShadow={false}
        />

        <CreatorPill
          onClick={() => setCurrentCreator(2)}
          title="Preppy Kitchen"
          subtitle="Spreading joy through..."
          image={PreppyHead}
          isActive={currentCreator === 2}
          alwaysShadow={false}
        />

        <CreatorPill
          onClick={() => setCurrentCreator(3)}
          title="Doobydobap"
          subtitle="annyeong doobies"
          image={DoobyHead}
          isActive={currentCreator === 3}
          alwaysShadow={false}
        />

        <CreatorPill
          onClick={() => setCurrentCreator(4)}
          title="deliciously ella"
          subtitle="real plant based food"
          image={EllaHead}
          isActive={currentCreator === 4}
          alwaysShadow={false}
        />

        <CreatorPill
          onClick={() => setCurrentCreator(5)}
          title="cbum"
          subtitle="cbumfitness.com"
          image={CbumHead}
          isActive={currentCreator === 5}
          alwaysShadow={false}
        />
      </StyledCreatorOptionsContainer>
      <StyledPhoneImageContainer>
        <StyledPhoneImage src={images[currentCreator]} alt={"phone image"}/>
      </StyledPhoneImageContainer>
    </StyledPanelContainer>
   </StyledMasterContainer>
  );
};

const StyledCreatorOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  justify-content: center;
`;

const StyledPhoneImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledPhoneImageContainer = styled.div`
  width: 400px;
  height: auto;
`;

const StyledPanelContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 64px;
`;

const StyledMasterContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
`