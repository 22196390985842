/** Style for Text
 * @props
 * fontWeight:
 * color?:  (default: )
 * textAlign?: (default: left)
 * lineHeight?:
 * letterSpacing?:
 * textDecorationLine?:
 */
import { colors } from "./colors";
import { Styles } from "styled-components/dist/types";
import styled from "styled-components";

export type SystemNames =
  | "Display_1"
  | "Display_2"
  | "Display_3"
  | "Display_4"
  | "Display_5"
  | "Display_6"
  | "Title_1"
  | "Title_2"
  | "Title_3"
  | "Title_4"
  | "Title_5"
  | "Title_6"
  | "Title_7"
  | "Subtitle_1"
  | "Subtitle_2"
  | "Subtitle_3"
  | "Subtitle_4"
  | "Subtitle_5"
  | "Body_1"
  | "Body_2"
  | "Body_3"
  | "Body_4"
  | "Body_5"
  | "Link_1"
  | "Link_2"
  | "Link_3"
  | "Link_4"
  | "Link_5"
  | "Caption_1"
  | "Caption_2"
  | "Caption_3"
  | "Caption_4"
  | "Caption_5"
  | "Tab_1";

export interface CustomTextStyle {
  color?: string;
  textAlign?: "auto" | "left" | "right" | "center" | "justify";
  lineHeight?: number;
  letterSpacing?: number;
  textDecorationLine?: "none" | "underline" | "line-through";
}

const styledCommonText = styled.s<CustomTextStyle>`
cursor: arrow;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "left")};
  text-decoration-line: ${({ textDecorationLine }) =>
    textDecorationLine ? textDecorationLine : "none"};
  color: ${({ color }) => (color ? color : colors.shade_2)};
`;

// Display
const Display_1 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 64px;
  font-weight: 700;
`;
const Display_2 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 56px;
  font-weight: 700;
`;
const Display_3 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 48px;
  font-weight: 700;
`;
const Display_4 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 40px;
  font-weight: 700;
`;
const Display_5 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 32px;
  font-weight: 700;
`;
const Display_6 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 24px;
  font-weight: 700;
`;
// Title
const Title_1 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 28px;
  font-weight: 700;
`;
const Title_2 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 24px;
  font-weight: 700;
`;
const Title_3 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 20px;
  font-weight: 700;
`;
const Title_4 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 18px;
  font-weight: 700;
`;
const Title_5 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 16px;
  font-weight: 700;
`;
const Title_6 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 14px;
  font-weight: 700;
`;
const Title_7 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 12px;
  font-weight: 700;
`;
// Subtitle
const Subtitle_1 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 20px;
  font-weight: 500;
`;
const Subtitle_2 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 18px;
  font-weight: 500;
`;
const Subtitle_3 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 16px;
  font-weight: 500;
`;
const Subtitle_4 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 14px;
  font-weight: 500;
`;
const Subtitle_5 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 12px;
  font-weight: 500;
`;
// Body
const Body_1 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 20px;
  font-weight: 400;
`;
const Body_2 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 18px;
  font-weight: 400;
`;
const Body_3 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 16px;
  font-weight: 400;
`;
const Body_4 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 14px;
  font-weight: 400;
`;
const Body_5 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 12px;
  font-weight: 400;
`;
// Link
const Link_1 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 20px;
  font-weight: 500;
`;
const Link_2 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 18px;
  font-weight: 500;
`;
const Link_3 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 16px;
  font-weight: 500;
`;
const Link_4 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 14px;
  font-weight: 500;
`;
const Link_5 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 12px;
  font-weight: 500;
`;
// Caption
const Caption_1 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 20px;
  font-weight: 300;
`;
const Caption_2 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 18px;
  font-weight: 300;
`;
const Caption_3 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 16px;
  font-weight: 300;
`;
const Caption_4 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 14px;
  font-weight: 300;
`;
const Caption_5 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 12px;
  font-weight: 300;
`;
// Logo
const Logo_1 = styled(styledCommonText)`
  font-family: Caladea;
  font-size: 64px;
  font-weight: 700;
`;
const Logo_2 = styled(styledCommonText)`
  font-family: Caladea;
  font-size: 48px;
  font-weight: 700;
`;
const Logo_3 = styled(styledCommonText)`
  font-family: Caladea;
  font-size: 32px;
  font-weight: 700;
`;
// Underline
const Underline_1 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 18px;
  font-weight: 700;
  text-decoration-line: underline;
`;
// Tab
const Tab_1 = styled(styledCommonText)`
  font-family: Satoshi;
  font-size: 16px;
  font-weight: 700;
`;

export const StyledText = {
  Display_1,
  Display_2,
  Display_3,
  Display_4,
  Display_5,
  Display_6,
  Title_1,
  Title_2,
  Title_3,
  Title_4,
  Title_5,
  Title_6,
  Title_7,
  Subtitle_1,
  Subtitle_2,
  Subtitle_3,
  Subtitle_4,
  Subtitle_5,
  Body_1,
  Body_2,
  Body_3,
  Body_4,
  Body_5,
  Link_1,
  Link_2,
  Link_3,
  Link_4,
  Link_5,
  Caption_1,
  Caption_2,
  Caption_3,
  Caption_4,
  Caption_5,
  Logo_1,
  Logo_2,
  Logo_3,
  Underline_1,
  Tab_1,
};
