import React, { useState, useRef } from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//Screens
import Ramsay from "../../assets/images/creator_profiles/gordonramsaymock.png";
import Cbum from "../../assets/images/creator_profiles/cbummock.png";
import Preppy from "../../assets/images/creator_profiles/preppykitchenmock.png";
import Ella from "../../assets/images/creator_profiles/delciouslyellamock.png";
import Dooby from "../../assets/images/creator_profiles/doobydobapmock.png";
import Feelgood from "../../assets/images/creator_profiles/feelgoodfoodiemock.png";

//Headshots
import RamsayHead from "../../assets/images/creator_headshots/gordonprofile.png";
import CbumHead from "../../assets/images/creator_headshots/cbumprofile.png";
import PreppyHead from "../../assets/images/creator_headshots/preppykitchenprofile.png";
import EllaHead from "../../assets/images/creator_headshots/delicouslyellaprofile.png";
import DoobyHead from "../../assets/images/creator_headshots/doobyprofile.png";
import FeelgoodHead from "../../assets/images/creator_headshots/feelgoodfoodieprofile.png";

import styled from "styled-components";
import { CorselSubheading } from "../atoms/CaroselSubheading";
import { colors } from "../../constants/colors";
import { CreatorPill } from "../molecules/CreatorPill";

const CreatorCarousel: React.FC = () => {
    const sliderRef = useRef<Slider>(null);
    const [currentSlide, setCurrentSlide] = React.useState<number>(0)

  const settings: Settings = {
    dots: false,
    infinite: true,
    speed: 500,
    swipeToSlide: true, 
    variableWidth: true, 
    autoplay: true, 
    autoplaySpeed: 3000, 
    pauseOnHover: true, 
    centerMode: true,
    focusOnSelect: true,
    afterChange: (current) => {
        setCurrentSlide(current);
      },
  };


  return (
    <div>
      <Slider {...settings} ref={sliderRef}>
        <div>
          <StyledContentContainer>
            <StyledCarouselImageContainer>
              <StyledCarouselImage src={Ramsay} alt={"Ramsay"} />
            </StyledCarouselImageContainer>
            <CreatorPill
              title="Gordon Ramsay"
              subtitle="Idiot sandwhich"
              image={RamsayHead}
              isActive={currentSlide === 0}
            />
          </StyledContentContainer>
        </div>
        <div>
          <StyledContentContainer>
            <StyledCarouselImageContainer>
              <StyledCarouselImage src={Feelgood} alt={"Feel good"} />
            </StyledCarouselImageContainer>
            <CreatorPill
              title="feelgoodfoodie"
              subtitle="always feel good"
              image={FeelgoodHead}
              isActive={currentSlide === 1}
            />
          </StyledContentContainer>
        </div>
        <div>
          <StyledContentContainer>
            <StyledCarouselImageContainer>
              <StyledCarouselImage src={Preppy} alt={"Preppy"} />
            </StyledCarouselImageContainer>
            <CreatorPill
              title="Preppy Kitchen"
              subtitle="Spreading joy through..."
              image={PreppyHead}
              isActive={currentSlide === 2}
            />
          </StyledContentContainer>
        </div>
        <div>
          <StyledContentContainer>
            <StyledCarouselImageContainer>
              <StyledCarouselImage src={Dooby} alt={"Dooby"} />
            </StyledCarouselImageContainer>
            <CreatorPill
              title="Doobydobap"
              subtitle="annyeong doobies"
              image={DoobyHead}
              isActive={currentSlide === 3}
            />
          </StyledContentContainer>
        </div>
        <div>
          <StyledContentContainer>
            <StyledCarouselImageContainer>
              <StyledCarouselImage src={Ella} alt={"Ella"} />
            </StyledCarouselImageContainer>
            <CreatorPill
              title="deliciously ella"
              subtitle="real plant based food"
              image={EllaHead}
              isActive={currentSlide === 4}
            />
          </StyledContentContainer>
        </div>
        <div>
          <StyledContentContainer>
            <StyledCarouselImageContainer>
              <StyledCarouselImage src={Cbum} alt={"Cbum"} />
            </StyledCarouselImageContainer>
            <CreatorPill
              title="cbum"
              subtitle="cbumfitness.com"
              image={CbumHead}
              isActive={currentSlide === 5}
            />
          </StyledContentContainer>
        </div>
      </Slider>
    </div>
  );
};

const StyledCarouselImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 19px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  cursor: pointer;
`;

const StyledCarouselImageContainer = styled.div`
  display: flex;
  width: 220px;
`;

export default CreatorCarousel;
