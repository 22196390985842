import styled from "styled-components";
import { colors } from "../../constants/colors";
import { StyledText } from "../../constants/text";
import { Icon } from "../Icon";

export const SurveyButton2: React.FC = () => {

    const openGoogleForm = () => {
        window.open("https://docs.google.com/forms/d/e/1FAIpQLSdJTJtV7ZqsDmRYDM6Tzyf2CyxhQL1QnU48KoxJS_4GaahhjA/viewform?usp=sf_link", "_blank");
      };
  return (
    <StyledSurveyButtonContainer onClick={openGoogleForm}>
        <StyledSurveyTextHeading color={colors.shade_2}>
         Complete our survey here
        </StyledSurveyTextHeading>
      <Icon type="arrowrightgrey" />
    </StyledSurveyButtonContainer>
  );
};

const StyledSurveyButtonContainer = styled.div`
    display: flex;
    width: 309px;
    height: 68px;
    border-radius: 55px;
    border-width: 1px;
    border-color: ${colors.neutral_3};
    background-color: ${colors.neutral_1};
    box-shadow: 0px 12px 27px rgba(0, 0, 0, 0.03);
    border-style: solid;
    gap: 30px;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media screen and (min-width: 1024px) {
        width: 397px;
        height: 85px;
      }
    @media screen and (min-width: 1440px) {
        width: 504px;
        height: 110px;
    }
    transition: all 0.5s ease;
`;

const StyledSurveyTextHeading = styled(StyledText.Title_5)`
  margin-top: 0px;
  margin-bottom: 0px;

  @media screen and (min-width: 1024px) {
    font-size: 20px;
  }
  transition: font-size 0.5s ease;
`;
