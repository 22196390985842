import styled from "styled-components"
import Carousel from "./Carousel"
import { ImagePanel } from "./ImagePanel"

export const CarouselHandler = () => {
    return (
        <MasterContainer>
            <PanelContainer>
                <ImagePanel/>
            </PanelContainer>
            <CarouselContainer>
                <Carousel/>
            </CarouselContainer>
        </MasterContainer>
    )
}



const CarouselContainer = styled.div`
width: 100%;
position: absolute;
opacity: 1;
@media screen and (min-width: 744px) {
    opacity: 0;
  }
`

const PanelContainer = styled.div`
display: flex;
width: 100%;
justify-content: center;
position: absolute;
opacity: 0;
@media screen and (min-width: 744px) {
    opacity: 1;
  }

`

const MasterContainer = styled.div`
margin-top: 125px;
position: relative;
height: 710px;
width: 100%;
@media screen and (min-width: 744px) {
    height: 470px;
    margin-top: 188px;
  }
  @media screen and (min-width: 1024px) {
    height: 640px;
    margin-top: 212px;
  }
  @media screen and (min-width: 1440px) {
    height: 710px;
  }
transition: all 0.5s ease;
`