import styled from "styled-components";
import { CreatorPanel } from "./CreatorPanel";
import CreatorCarousel from "./CreatorCarousel";

export const CreatorHandler = () => {
  return (
    <div>
      <CreatorPanelContainer>
        <CreatorPanel />
      </CreatorPanelContainer>
      <CreatorCarouselContainer>
        <CreatorCarousel />
      </CreatorCarouselContainer>
    </div>
  );
};

const CreatorPanelContainer = styled.div`
  display: none;
  @media screen and (min-width: 1024px) {
    display: block;
  }
`;

const CreatorCarouselContainer = styled.div`
  display: block;
  position: relative;
  @media screen and (min-width: 1024px) {
    display: none;
  }
`;
