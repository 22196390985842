import styled from "styled-components";
import { colors } from "../../constants/colors";
import { StyledText } from "../../constants/text";
import { Icon } from "../Icon";

export const SurveyButton: React.FC = () => {
  const openGoogleForm = () => {
    window.open("https://docs.google.com/forms/d/e/1FAIpQLSdJTJtV7ZqsDmRYDM6Tzyf2CyxhQL1QnU48KoxJS_4GaahhjA/viewform?usp=sf_link", "_blank");
  };
  return (
    <StyledSurveyButtonContainer onClick={openGoogleForm}>
      <StyledSurveyTextContainer>
        <StyledSurveyTextHeading color={colors.neutral_7}>
          FILL OUT OUR SURVEY HERE
        </StyledSurveyTextHeading>
        <StyledSurveyTextSubheading color={colors.shade_2}>
          Help us turn this into a reality
        </StyledSurveyTextSubheading>
      </StyledSurveyTextContainer>
      <Icon type="arrowrightgrey" />
    </StyledSurveyButtonContainer>
  );
};

const StyledSurveyButtonContainer = styled.div`
  display: flex;
  width: 339px;
  height: 68px;
  border-radius: 55px;
  border-width: 1px;
  border-color: ${colors.neutral_3};
  background-color: ${colors.neutral_1};
  box-shadow: 0px 12px 27px rgba(0, 0, 0, 0.03);
  border-style: solid;
  gap: 30px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media screen and (min-width: 1024px) {
    width: 398px;
    height: 90px;
  }
  @media screen and (min-width: 1440px) {
    width: 504px;
    height: 110px;
  }
  transition: width 0.5s ease, height 0.5s ease; /* Only transition width and height */
`;
const StyledSurveyTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSurveyTextHeading = styled(StyledText.Subtitle_5)`
  margin-top: 0px;
  margin-bottom: 0px;

  @media screen and (min-width: 1024px) {
    font-size: 14px;
  }
  transition: all 0.5s ease;
`;
const StyledSurveyTextSubheading = styled(StyledText.Title_5)`
  margin-top: 0px;
  margin-bottom: 0px;
  @media screen and (min-width: 1024px) {
    font-size: 20px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 24px;
  }
  transition: all 0.5s ease;
`;
