import styled from "styled-components";
import { Nav } from "../components/Nav";
import { Survey } from "../components/Survey";
import { StyledText } from "../constants/text";
import { colors } from "../constants/colors";
import Nick from "../assets/images/creator_profiles/nickhomewebsite.png";
import { SurveyButton } from "../components/molecules/SurveyButton";
import { CostRowPanel } from "../components/organisms/CostRowPanel";
import { Footer } from "../components/molecules/Footer";
import { CarouselHandler } from "../components/organisms/CarouselHandler";
import { SurveyButton2 } from "../components/molecules/SurveyButton2";
import { CreatorHandler } from "../components/organisms/CreatorHandler";

export const Home = () => {
  return (
    <StyledHomePageContainer>
      <Nav />
      <StyledMainContainer>
        <StyledHeroContainer>
          <StyledTextHeroContainerWithSurvey>
            <StyledTextHeroContainer>
              <StyledTextHero color={colors.shade_2}>
                Spotify pays artists for their music, you should
              </StyledTextHero>
              <StyledTextHero color={colors.primary_1}>
                get paid for your recipes
              </StyledTextHero>
              <StyledTextSubHero color={colors.neutral_6}>
                Monetize your creations with salt
              </StyledTextSubHero>
            </StyledTextHeroContainer>
            <SurveyButton />
          </StyledTextHeroContainerWithSurvey>
          <StyledPhoneImageContainer>
            <StyledPhoneImage src={Nick} alt={"nick"} />
          </StyledPhoneImageContainer>
        </StyledHeroContainer>
        <StyledCostRowPanelBackground>
          <StyledTextHero2 color={colors.shade_2}>
            Make more from your recipes
          </StyledTextHero2>
          <CostRowPanel />
        </StyledCostRowPanelBackground>
        <CarouselHandler />
        <StyledSurveyButton2Container>
          <SurveyButton2 />
        </StyledSurveyButton2Container>
        <StyledLine />
        <StyledCreatorHeadingMasterContainer>
          <StyledHeadingContainer>
            <StyledHeading color={colors.shade_2}>
              Give back to your following
            </StyledHeading>
            <StyledSubheading color={colors.neutral_6}>
              A user-friendly system to navigate creator recipes
            </StyledSubheading>
          </StyledHeadingContainer>
        </StyledCreatorHeadingMasterContainer>

        <CreatorHandler />
        <Footer />
      </StyledMainContainer>
    </StyledHomePageContainer>
  );
};
const StyledHomePageContainer = styled.div`
  overflow-x: hidden; /* Hide horizontal scrollbar */
  overflow-y: auto; /* Allow vertical scrollbar when content overflows vertically */
`;
const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 36px;
  @media screen and (min-width: 744px) {
    margin-top: 82px;
  }
  @media screen and (min-width: 1024px) {
    margin-top: 93px;
  }
  @media screen and (min-width: 1440px) {
    margin-top: 80px;
  }
  @media screen and (min-width: 1920px) {
    margin-top: 125px;
  }
  transition: margin-top 0.5s ease;
`;

const StyledTextHero = styled(StyledText.Title_2)`
  text-align: center;

  @media screen and (min-width: 744px) {
    text-align: left;
  }
  @media screen and (min-width: 834px) {
    font-size: 28px;
  }
  @media screen and (min-width: 1024px) {
    font-size: 40px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 48px;
  }
  transition: font-size 0.5s ease;
  margin-top: 0;
  margin-bottom: 0;
`;

const StyledTextSubHero = styled(StyledText.Subtitle_3)`
  text-align: center;
  margin-top: 16px;
  @media screen and (min-width: 744px) {
    text-align: left;
  }
  @media screen and (min-width: 1024px) {
    font-size: 20px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 24px;
  }
  transition: all 0.5s ease;
  margin-top: 16;
  margin-bottom: 0;
`;

const StyledTextHeroContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 279px;
  @media screen and (min-width: 834px) {
    width: 327px;
  }
  @media screen and (min-width: 1024px) {
    width: 456px;
  }
  @media screen and (min-width: 1440px) {
    width: 583px;
  }
  transition: all 0.5s ease;
`;

const StyledPhoneImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledPhoneImageContainer = styled.div`
  width: 250px;
  height: auto;
  order: -1;
  @media screen and (min-width: 744px) {
    order: 0;
  }
  @media screen and (min-width: 1024 px) {
    width: 304px;
  }

  transition: width 0.5s ease;
`;

const StyledHeroContainer = styled.div`
  gap: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 744px) {
    flex-direction: row;
    gap: 16px;
  }
  @media screen and (min-width: 834px) {
    gap: 96px;
  }
  @media screen and (min-width: 834px) {
    gap: 96px;
  }
  @media screen and (min-width: 1024px) {
    gap: 103px;
  }
  @media screen and (min-width: 1440px) {
    gap: 171px;
  }
  @media screen and (min-width: 1920px) {
    gap: 224px;
  }
  transition: gap 0.5s ease;
`;

const StyledTextHeroContainerWithSurvey = styled.div`
  display: flex;
  flex-direction: column;
  gap: 41px;
  align-items: center;
  @media screen and (min-width: 744px) {
    align-items: flex-start;
  }
`;

const StyledTextHero2 = styled(StyledText.Title_2)`
  width: 287px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  @media screen and (min-width: 744px) {
    font-size: 48px;
    width: 584px;
  }
  @media screen and (min-width: 1024px) {
    width: 670px;
  }
  transition: all 0.5s ease;
`;

const StyledCostRowPanelBackground = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: center;
  justify-content: center;
  margin-left: 80px;
  margin-right: 80px;

  @media screen and (min-width: 834px) {
    gap: 28px;
  }
  @media screen and (min-width: 1024px) {
    background-color: #f5f5f7;
    border-radius: 28px;
    border-width: 1px;
    border-style: solid;
    border-color: #dbdbdb;
    padding-bottom: 90px;
    padding-top: 58px;
    padding-left: 97px;
    padding-right: 97px;
    gap: 64px;
  }
  @media screen and (min-width: 1440px) {
    gap: 43px;
  }
  transition: all 0.5s ease;
`;

const StyledHeading = styled(StyledText.Title_2)`
  margin-top: 0px;
  margin-bottom: 0px;
  @media screen and (min-width: 1024px) {
    font-size: 64px;
  }
  transition: font-size 0.5s ease;
`;
const StyledSubheading = styled(StyledText.Subtitle_3)`
  margin-top: 0px;
  margin-bottom: 0px;
  @media screen and (min-width: 1024px) {
    font-size: 32px;
    font-weight: 500;
  }
  transition: all 0.5s ease;
`;
const StyledHeadingContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 48px;
`;

const StyledCreatorHeadingMasterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const StyledLine = styled.div`
  border-top-width: 1px;
  border-top-style: solid;
  border-color: ${colors.neutral_3};
  width: 90%;

  margin-top: 48px;
  margin-bottom: 50px;

  @media screen and (min-width: 744px) {
    margin-top: 101px;
    margin-bottom: 48px;
  }
  @media screen and (min-width: 1024px) {
    margin-top: 109px;
    margin-bottom: 102px;
  }
  @media screen and (min-width: 1440px) {
    margin-top: 125px;
    margin-bottom: 95px;
  }
  @media screen and (min-width: 1920px) {
    margin-top: 99px;
    margin-bottom: 99px;
  }

  transition: all 0.5s ease;
`;

const StyledSurveyButton2Container = styled.div`
  margin-top: 44px;
`;
