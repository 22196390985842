import styled from "styled-components";
import { colors } from "../../constants/colors";
import { StyledText } from "../../constants/text";

interface Props {
  title: string;
  subtitle: string;
  image: string;
  onClick?: () => void;
  isActive?: boolean;
  alwaysShadow?: boolean;
}

export const CreatorPill: React.FC<Props> = ({
  title,
  subtitle,
  image,
  onClick,
  isActive=false,
  alwaysShadow = true,

}) => {
  return (


    <StyledPillContainer onClick={()=> {onClick && onClick()}}isActive={isActive} alwaysShadow={alwaysShadow}>
      <StyledImage src={image} alt="creator image" />
      <StyledTextContainer>
        <StyledHeading color={colors.shade_2}>{title}</StyledHeading>
        <StyledSubheading color={colors.neutral_7}>{subtitle}</StyledSubheading>
      </StyledTextContainer>
    </StyledPillContainer>
  );
};


const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
const StyledPillContainer = styled.div<{isActive:boolean, alwaysShadow: boolean}>`
box-sizing: border-box;
  display: flex;
  width: 220px;
  height: 74px;
  gap: 12px;
  align-items: center;
  background-color: ${({isActive}) => (isActive ? colors.shade_1 : colors.neutral_1)};
  border-width: 1px;
  border-radius: 61px;
  border-color: ${colors.neutral_3};

  padding-left: 16px;
  box-shadow:  ${({isActive, alwaysShadow}) => (alwaysShadow || isActive) && `0px 1px 3px 0px rgba(0, 0, 0, 0.05),
  0px 6px 6px 0px rgba(0, 0, 0, 0.04), 0px 12px 7px 0px rgba(0, 0, 0, 0.03),
  0px 22px 9px 0px rgba(0, 0, 0, 0.01), 0px 34px 10px 0px rgba(0, 0, 0, 0)`};
    
  

  cursor: pointer;

  @media screen and (min-width: 1024px) {
    width: 307px;
    height: 106px;
  }

transition: all 0.5s ease;
`;

const StyledImage = styled.img`
  width: 50px;
  height: 50px;
  @media screen and (min-width: 1024px) {
    width: 70px;
    height: 70px;
  }
`;

const StyledHeading = styled(StyledText.Title_6)`
margin-top: 0px;
margin-bottom: 0px;
@media screen and (min-width: 1024px) {
    font-size: 16px;
  }
`
const StyledSubheading = styled(StyledText.Subtitle_5)`
margin-top: 0px;
margin-bottom: 0px;
@media screen and (min-width: 1024px) {
    font-size: 14px;
  }
`
